import React, { useMemo, useState, Fragment } from "react"
import sqlQuery from "alasql";
import AnimatedPage from "../../components/AnimatedPage"
import { useStore, useStoreServices, useStoreSettings } from "../../zustand/store"
import Tree from "../../components/reports/Tree"
import { jsonToTreeData } from "../../library/functions"
import { serviceDataSettings } from "../../components/reports/serviceDataSetting"
import Table from "../../components/reports/Table"
import InternalInvoiceTable from "../../components/internal_invoice/Table"
import { Draggable } from "react-drag-reorder"
import ExcelDownload from "../../components/reports/ExcelDownload"
import { useEffect } from "react"
import axios from "axios"
import { apiUrl, config } from "../../library/constant"
import { toast } from "react-toastify"
import NoAccess from "../NoAccess"
import { theEquivament } from "../../components/service_request/Table"
import { useForm } from "react-hook-form"
import Select from "../../components/inputs/Select"
import ModalUni from "../../components/modals/ModalUni"
import ServiceReportSaveTemplate from "../../components/dashboard/ServiceReportSaveTemplate"
import ServiceReportAllCustomFilter from "../../components/dashboard/ServiceReportAllCustomFilter"
import ServiceReportOrderBy from "../../components/dashboard/ServiceReportOrderBy"

import RCNumberSearchInput from "../../components/inputs/RCNumberSearchInput"
import RCPDFReports from "./RCPDFReports"

const ServicesReports = () => {
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm()
  const watcher = watch()
  const [selectedColumns, setSelectedColumns] = useState([])
  const [selectedSettings, setSelectedSettings] = useState(null)

  const [searchText, setSearchText] = useState("")
  const [selected, setSelected] = useState(0)

  // Add Template Modal
  const [isModalSaveTemplateVisible, setIsModalSaveTemplateVisible] = useState(false)
  const [isModalCustomFilterVisible, setIsModalCustomFilterVisible] = useState(false)
  const [isModalSortVisible, setIsModalSortVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [viewWhat, setViewWhat] = useState("")

  const [internalInvoice, setInternalInvoice] = useState([])
  const [showRCPDFReports, setShowRCPDFReports] = useState(false)
  const workshop = useStore((state) => state.workshop)

  const services = useStoreServices((s) => s.services)
  const settings = useStoreSettings((s) => s.settings)
  const setSettings = useStoreSettings((s) => s.setSettings)
  const updateSetting = useStoreSettings((s) => s.updateSetting)

  const [forceUpdate, setForceUpdate] = useState(0)

  const viewSaveTemplate = (second) => {
    setIsModalSaveTemplateVisible(true)
    setViewWhat(second)
  }

  const viewCustomFilter = (second) => {
    setIsModalCustomFilterVisible(true)
    setViewWhat(second)
  }

  const viewSort = (second) => {
    setIsModalSortVisible(true)
    setViewWhat(second)
  }
  
  const forceUpdateComponent = () => {
    setForceUpdate(prev => prev + 1)
  }

  const handleTemplateOnChange = (e) => {
    const selectedTemplate = settings.find(item => item.id == e.target.value)
    setSelectedSettings(selectedTemplate)
    setSelectedColumns(JSON.parse(selectedTemplate.param))
    setValue("template", selectedTemplate.id)
  }

  const saveServiceReportTemplate = (id, templateName) => {

    if (id != null) {
      const settingItem = {
        id: id,
        type: templateName
      }
      axios
      .put(apiUrl + "/settings/type/rename", settingItem, config())
      .then((res) => {
        if (res.data?.status === "true") {
          for (let updateSettingLength = 0; updateSettingLength < settings.length; updateSettingLength++) {
            if (settings[updateSettingLength].id == id) {
              settings[updateSettingLength].type = templateName
            }
          }
          setIsModalSaveTemplateVisible(false)
          toast.success(res.data?.message)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(`Error: there's was a problem adding settings for Report`)
      })
    } else {
      const settingItem = {
        type_key: "report",
        item_key: "services",
        type: templateName,
        param: JSON.stringify([]),
        customFilter: JSON.stringify([])
      }
      axios
      .post(apiUrl + "/settings", settingItem, config())
      .then((res) => {
        if (res.data?.status === "true") {
          settings.push(res.data?.results)
          // updateSetting(res.data?.results)
          
          // select the new added template
          setSelectedSettings(res.data?.results)
          setSelectedColumns(JSON.parse(res.data?.results?.param))
          setValue("template", res.data?.results?.id)
          forceUpdateComponent()
          
          setIsModalSaveTemplateVisible(false)
          toast.success(res.data?.message)
        } else {
          toast.error(res.data?.message)
        }
      })
      .catch((error) => {
        toast.error(`Error: there's was a problem adding settings for Report`)
      })

    }
  }

  useEffect(() => {
    const getData = setTimeout(async () => {
      // console.log("SendingShit.")
      const settingItem = {
        id: selectedSettings.id,
        type_key: "report",
        item_key: "services",
        type: selectedSettings.type,
        param: JSON.stringify(selectedColumns),
        customFilter: JSON.stringify([])
      }

      // console.log("settingItem", settingItem);

      axios
        .put(apiUrl + "/settings", settingItem, config())
        .then((res) => {
          // console.log("Update response", res.data)
          updateSetting(res.data)
          // toast.success("Saved")
        })
        .catch((error) => {
          toast.error(`Error: there's was a problem updating settings for Report`)
        })
    }, 2000)

    return () => clearTimeout(getData)
  }, [selectedColumns, selectedSettings, updateSetting])

  const procData = useMemo(() => {
    const newData = jsonToTreeData(serviceDataSettings)
    return {
      id: 1,
      name: "Service Request Api Data",
      children: newData,
    }
  }, [])

  const selectedColumnsWithData = useMemo(() => {
    const convertedTableData = [...services]
    const tableData = []

    for (let index = 0; index < convertedTableData.length; index++) {
      const tableRow = convertedTableData[index]

      const newTableRow = {}

      for (let index = 0; index < selectedColumns.length; index++) {
        const column = selectedColumns[index]

        if (column.keys.length === 0) {
          try {
            newTableRow[column.id] = tableRow[column.key]
          } catch (error) {
            newTableRow[column.id] = ""
          }
        }

        if (column.keys.length === 1) {
          try {
            const myVar = tableRow[column.keys[0]]
            if (typeof myVar === "string" || myVar instanceof String) {
              const newObject = JSON.parse(myVar)
              newTableRow[column.id] = newObject[column.key]
            } else {
              newTableRow[column.id] = tableRow[column.keys[0]][column.key]
            }
          } catch (error) {
            newTableRow[column.id] = ""
          }
        }

        if (column.keys.length === 2) {
          try {
            const myVar = tableRow[column.keys[1]][column.keys[0]]

            if (typeof myVar === "string" || myVar instanceof String) {
              const newObject = JSON.parse(myVar)
              newTableRow[column.id] = newObject[column.key]
            } else {
              newTableRow[column.id] = tableRow[column.keys[1]][column.keys[0]][column.key]
            }
          } catch (error) {
            newTableRow[column.id] = ""
          }
        }
      }

      tableData.push(newTableRow)
    }

    const isValidDate = (value) => {
      value =  typeof value === "number" ? String(value) : value?.trim();
      const dateRegex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[\+\-]\d{2}:\d{2})?)?$/
      if (dateRegex.test(value)) {
        const date = new Date(value)
        return !isNaN(date.getTime())
      }
      return false
    }

    const isValidNumber = (value) => {
      value =  typeof value === "number" ? String(value) : value?.trim();
      const numberRegex = /^-?\d+(\.\d+)?$/
      return numberRegex.test(value) && isFinite(value)
    }

    const formatDate = (date) => {
      if (!(date instanceof Date)) {
        date = new Date(date)
      }
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    const validateFieldDataType = (value) => {
      if (isValidDate(value)) {
        return formatDate(value)
      } else if (isValidNumber(value)) {
        return parseFloat(value)
      } else {
        return value 
      }
    }

    const validateValueDataType = (value) => {
      if (isValidDate(value)) {
        return formatDate(value)
      } else if (isValidNumber(value)) {
        return parseFloat(value)
      } else {
        return value 
      }
    }

  // Helper function to check if a value is a valid date
  const isValidReportDate = (dateString) => {
    // Updated regular expression to validate 'YYYY-MM-DDTHH:MM:SS.ssssss+00:00' format with optional milliseconds
    const isoFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,6})?\+00:00$/;
  
    // Check if the dateString matches the format
    if (!isoFormatRegex.test(dateString)) {
      return false; // Invalid format
    }
  
    // Parse the date and check if it's a valid date
    const date = new Date(dateString);
    return !isNaN(date.getTime()); // Return true if the date is valid
  }

  // Helper function to format the date into 'DD/MM/YYYY hh:mm AM/PM'
  const formatReportDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    
    // Get hours and minutes
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Determine AM or PM suffix
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? String(hours).padStart(2, '0') : '12'; // the hour '0' should be '12'

    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  }

  // Function to check and convert dates in the object
  const checkAndConvertDates = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (isValidReportDate(value)) {
        acc[key] = formatReportDate(value);  // Convert the date to readable format
      } else {
        acc[key] = value;  // Keep the original value if not a valid date
      }
      return acc;
    }, {});
  }

    sqlQuery.fn.formatDate = formatDate
    sqlQuery.fn.validateFieldDataType = validateFieldDataType
    sqlQuery.fn.validateValueDataType = validateValueDataType

    let filters = []
    // const sorts = []
    for (let selectedColumnLength = 0; selectedColumnLength < selectedColumns.length; selectedColumnLength++) {
      // Custom Filter
      if (selectedColumns[selectedColumnLength].hasOwnProperty('customFilter') && selectedColumns[selectedColumnLength].customFilter.length > 0) {
        for (let filterLength = 0; filterLength < selectedColumns[selectedColumnLength].customFilter.length; filterLength++) {
          filters.push(selectedColumns[selectedColumnLength].customFilter[filterLength])
        }
      }
      // Sort
      // if (selectedColumns[selectedColumnLength].hasOwnProperty('sort') && ['ASC', 'DESC'].includes(selectedColumns[selectedColumnLength].sort)) {
      //   sorts.push({ field: selectedColumns[selectedColumnLength].id.replace("Service Request Api Data.", ""), order: selectedColumns[selectedColumnLength].sort })
      // }
    }

    // AlaSQL Query
    let sqlString = "SELECT * FROM ?"

    // Filter by sequence
    filters = filters.sort((a, b) => { return a.seq - b.seq; })
    
    if (filters.length > 0) sqlString += " WHERE"
    
    for (let filtersLength = 0; filtersLength < filters.length; filtersLength++) {
      // Arithmetic Operator
      if (['=', '!=', '<', '>', '<=', '>='].includes(filters[filtersLength].operator)) {
        sqlString += (filters[filtersLength].logicalOp === "" ? "" : " " + filters[filtersLength].logicalOp) + " validateFieldDataType(`" + filters[filtersLength].field + "`) " + (filters[filtersLength].operator === "==" ? "=" : filters[filtersLength].operator) + " validateValueDataType('" + filters[filtersLength].value + "')"
        // if (filters[filtersLength].inputType === "number") {
        //   const value = typeof filters[filtersLength].value === "number" ? Number(filters[filtersLength].value) : filters[filtersLength].value
        //   sqlString += (filters[filtersLength].logicalOp === "" ? "" : " " + filters[filtersLength].logicalOp) + " `" + filters[filtersLength].field + "` " + (filters[filtersLength].operator === "==" ? "=" : filters[filtersLength].operator) + " " + value
        // } else if (filters[filtersLength].inputType === "text") {
        //   sqlString += (filters[filtersLength].logicalOp === "" ? "" : " " + filters[filtersLength].logicalOp) + " `" + filters[filtersLength].field + "` " + (filters[filtersLength].operator === "==" ? "=" : filters[filtersLength].operator) + " '" + filters[filtersLength].value + "'"
        // } else if (filters[filtersLength].inputType === "date") {
        //   sqlString += (filters[filtersLength].logicalOp === "" ? "" : " " + filters[filtersLength].logicalOp) + " formatDate(`" + filters[filtersLength].field + "`) " + (filters[filtersLength].operator === "==" ? "=" : filters[filtersLength].operator) + " formatDate('" + filters[filtersLength].value + "')"
        // }
        // Remove blank data
        if (['<', '>', '<=', '>='].includes(filters[filtersLength].operator)) {
          sqlString += " AND `" + filters[filtersLength].field + "` != ''"
        }
      }

      // Like Operators
      if (['contains', 'not_contains'].includes(filters[filtersLength].operator)) {
        if (filters[filtersLength].keywordAlign === "start") {
          sqlString += (filters[filtersLength].logicalOp === "" ? "" : " " + filters[filtersLength].logicalOp) + " `" + filters[filtersLength].field + "` " + (filters[filtersLength].operator === "contains" ? "LIKE" : "NOT LIKE") + " '" + filters[filtersLength].value + "%'"
        } else if (filters[filtersLength].keywordAlign === "center") {
          sqlString += (filters[filtersLength].logicalOp === "" ? "" : " " + filters[filtersLength].logicalOp) + " `" + filters[filtersLength].field + "` " + (filters[filtersLength].operator === "contains" ? "LIKE" : "NOT LIKE") + " '%" + filters[filtersLength].value + "%'"
        } else if (filters[filtersLength].keywordAlign === "end") {
          sqlString += (filters[filtersLength].logicalOp === "" ? "" : " " + filters[filtersLength].logicalOp) + " `" + filters[filtersLength].field + "` " + (filters[filtersLength].operator === "contains" ? "LIKE" : "NOT LIKE") + " '%" + filters[filtersLength].value + "'"
        }
      }

      // Date Range
      if (['date_range'].includes(filters[filtersLength].operator)) {
        sqlString += (filters[filtersLength].logicalOp === "" ? "" : " " + filters[filtersLength].logicalOp) + " formatDate(`" + filters[filtersLength].field + "`) >= '" + filters[filtersLength].dateFrom + "' AND formatDate(`" + filters[filtersLength].field + "`) <= '" + filters[filtersLength].dateTo + "'"
      } 
    }

    // Order By (ASC/DESC)
    // if (sorts.length > 0) sqlString += " ORDER BY"
    // for (let sortsLength = 0; sortsLength < sorts.length; sortsLength++) {
    //   sqlString += " `" + sorts[sortsLength].field + "` " + sorts[sortsLength].order
    // }

    // console.log("sqlString", sqlString)

    let filteredData = tableData

    try {
      filteredData = sqlQuery(sqlString, [tableData]) 
    } catch (error) {
      console.error(error)
    }

    // convert all date to 
    filteredData = filteredData.map(item => checkAndConvertDates(item))

    return filteredData
  }, [selectedColumns, services])

  const selectedColumnsWithDataNames = useMemo(() => {
    const convertedTableData = [...services]
    const tableData = []

    for (let index = 0; index < convertedTableData.length; index++) {
      const tableRow = convertedTableData[index]

      const newTableRow = {}

      for (let index = 0; index < selectedColumns.length; index++) {
        const column = selectedColumns[index]
        if (column.keys.length === 0) {
          try {
            newTableRow[column.name] = tableRow[column.key]
          } catch (error) {
            newTableRow[column.name] = ""
          }
        }
        if (column.keys.length === 1) {
          try {
            newTableRow[column.name] = tableRow[column.keys[0]][column.key]
          } catch (error) {
            newTableRow[column.name] = ""
          }
        }
        if (column.keys.length === 2) {
          try {
            newTableRow[column.name] = tableRow[column.keys[0]][column.keys[1]][column.key]
          } catch (error) {
            newTableRow[column.name] = ""
          }
        }
      }

      tableData.push(newTableRow)
    }
    return tableData
  }, [selectedColumns, services])

  // const serviceReportSettings = useMemo(() => {
  //   const reportSetting = settings.find((s) => s.type_key === "report" && s.item_key === "services")
  //   console.log(reportSetting)
  //   setSelectedColumns(JSON.parse(reportSetting.param))
  //   return reportSetting
  // }, [])

  const printToPDF = () => {
    setShowRCPDFReports(true)
  }

  const downloadExcel = () => {
    alert("download Excel");
  }

  const countAllCustomFilter = (data) => {
    let count = 0
    for (let dataLength = 0; dataLength < data.length; dataLength++) {
      for (let customFilterLength = 0; customFilterLength < data[dataLength].customFilter.length; customFilterLength++) {
        count++
      }
    }
    return count
  }

  const serviceReportsSettings = useMemo(() => {
    const xsetting = settings
      .filter((s) => s.type_key === "report" && s.item_key === "services")
      .slice()
      .sort((a, b) => a.type.localeCompare(b.type))
    if (!selectedSettings) {
      // console.log(xsetting[0])
      setSelectedSettings(xsetting[0])
      setSelectedColumns(JSON.parse(xsetting[0].param))
      setValue("template", xsetting[0].id)
    }

    // setLoadColumnTree(false)

    // console.log("xsetting", xsetting)
    return xsetting
  }, [settings, selectedSettings, setValue, forceUpdate])

  useEffect(() => {
    // console.log(watcher.template)
    const xsetting = settings.find((s) => s.id === Number(watcher.template))
    if (xsetting?.param) {
      setSelectedColumns(JSON.parse(xsetting.param))
      setSelectedSettings(xsetting)
    }
  }, [watcher.template, settings])
  
  const privileges = useStore((state) => state.privileges)

  if (!Boolean(privileges[15])) {
    return <NoAccess />
  }

  return (
    <AnimatedPage title="Reports">
      <ModalUni isVisible={isModalSaveTemplateVisible} setIsVisible={setIsModalSaveTemplateVisible} size={7} title={modalTitle}>
        <ServiceReportSaveTemplate viewWhat={viewWhat} templateId={watcher?.template} serviceReportsSettings={serviceReportsSettings} saveServiceReportTemplate={saveServiceReportTemplate} />
      </ModalUni>
      <ModalUni isVisible={isModalCustomFilterVisible} setIsVisible={setIsModalCustomFilterVisible} size={11} title={modalTitle}>
        <ServiceReportAllCustomFilter 
          setIsModalCustomFilterVisible={setIsModalCustomFilterVisible} 
          selectedData={selectedColumns} 
          setSelectedData={setSelectedColumns} 
        />
      </ModalUni>
      <ModalUni isVisible={isModalSortVisible} setIsVisible={setIsModalSortVisible} size={10} title={modalTitle}>
        <ServiceReportOrderBy 
          setIsModalSortVisible={setIsModalSortVisible} 
          selectedData={selectedColumns} 
          setSelectedData={setSelectedColumns} 
        />
      </ModalUni>
      <div className="tabs">
        <div className={`tab tab-lifted ${selected === 0 ? "tab-active" : ""}`} onClick={() => setSelected(0)}>
          Services
        </div>
        {/* <div className={`tab tab-lifted ${selected === 1 ? "tab-active" : ""}`} onClick={() => setSelected(1)}>
          Replacement Cars
        </div> */}
      </div>
      {selected === 0 ? (
        <div className="flex">
          <div className="flex-col flex-1 pr-2 mr-2 overflow-y-auto ">
            <div className="flex justify-end mb-2 pt-2">
              {/* <ExcelDownload columns={selectedColumns} dataset={selectedColumnsWithData} title={"Services Record"} /> */}
              <Select
                register={register}
                name="template"
                placeholder="Select Template"
                type="text"
                condition={{ required: true }}
                error={errors}
                message=""
                items={serviceReportsSettings}
                selectKey="type"
                className="w-56 mr-4"
                onChange={handleTemplateOnChange}
                // hasAddItem={true}
              />
              <div 
                className="px-4 py-2 text-white rounded-md btn btn-success mr-2"
                title="Rename"
                onClick={() => {
                  viewSaveTemplate("renameTemplate")
                  setModalTitle("Rename")
                }}
              >
                <i className="fa-solid fa-edit text-lg"/>
              </div>
              <div 
                className="px-4 py-2 text-white rounded-md btn btn-success mr-2"
                title="Add New"
                onClick={() => {
                  viewSaveTemplate("addTemplate")
                  setModalTitle("Add New")
                }}
              >
                <i className="fa-solid fa-plus text-lg"/>
              </div>
              <ExcelDownload excelData={selectedColumnsWithData} fileName={"Services Record"} isIcon={true} />
              <div 
                className="px-4 py-2 text-white rounded-md btn btn-success ml-2"
                title="Custom Filter"
                onClick={() => {
                  viewCustomFilter("allCustomFilter")
                  setModalTitle("Custom Filter")
                }}
              >
                <div className="relative inline-block">
                  <i className="fas fa-filter text-lg"/>
                  {selectedColumns.filter(item => item.hasOwnProperty('customFilter') && item?.customFilter?.length > 0).length > 0 && 
                    <div className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-[#F06400] text-white text-[10px] font-bold px-[5px] py-[1px] rounded-full">
                      {countAllCustomFilter(selectedColumns)}
                    </div>
                  }
                </div>

              </div>
              {/* <div 
                className="px-4 py-2 text-white rounded-md btn btn-success ml-2"
                title="Sort"
                onClick={() => {
                  viewSort("sort")
                  setModalTitle("Sort")
                }}
              >
                <div className="relative inline-block">
                  <i className="fas fa-sort text-lg"/>
                  {selectedColumns.filter(item => item.hasOwnProperty('sort') && item.sort !== "").length > 0 && 
                    <div className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-[#F06400] text-white text-[10px] font-bold px-[5px] py-[1px] rounded-full">
                      {selectedColumns.filter(item => item.hasOwnProperty('sort') && item.sort !== "").length}
                    </div>
                  }
                </div>
              </div> */}
              
              {/* <div className="px-4 py-2 text-white rounded-md btn btn-success">Download Excel</div> */}
            </div>
              {selectedColumns.length > 0 ? 
                <div className=" max-h-[calc(100vh-286px)] overflow-x-auto border border-[#F06400] scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100">
                  <Table data={selectedColumnsWithData} columns={selectedColumns} />
                </div>
              : 
                <div className="flex items-center justify-center">
                  <span className="mr-3">Select Required Columns</span><i className="fa-solid fa-arrow-right"/>
                </div>
              }
          </div>
          <div className="max-h-[calc(100vh-230px)] overflow-y-auto overflow-x-hidden rounded-lg p-3 border border-[#F06400] scrollbar-thin scrollbar-thumb-[#F06400] scrollbar-track-gray-100">
            {settings.filter((s) => s.type_key === "report" && s.item_key === "services").map((item, index) => {
              return (
                <Fragment key={index}>
                  {selectedSettings?.type === item?.type && 
                    <Tree
                      data={procData}
                      setSelectedData={setSelectedColumns}
                      selectedData={selectedColumns}
                      setting={selectedSettings}
                      setForceUpdate={setForceUpdate}
                    />
                  }
                </Fragment>
              )
            })}
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="flex flex-col justify-between w-full gap-4 md:flex-row mt-3">
            <div className="flex gap-5">
              <RCNumberSearchInput onTextChange={setSearchText} />
            </div>
            <div className="flex gap-2">
              <div className="px-1 py-2 rounded-md cursor-pointer" onClick={() => printToPDF()}>
                <i className="fa-solid fa-file-pdf text-3xl text-[#F06400]"/>
              </div>
              <div className="px-1 py-2 rounded-md cursor-pointer" onClick={() => downloadExcel()}>
                <i className="fa-solid fa-file-excel text-3xl text-[#F06400]"/>
              </div>
              {/* <ExcelDownload excelData={internalInvoice} fileName={"Internal Invoice"} /> */}
            </div>
          </div>
          <InternalInvoiceTable searchText={searchText} />
        </Fragment>
      )}

    {showRCPDFReports && 
      <div className="fixed inset-0 bg-gray-900 bg-opacity-80 z-[10001]">
        <span className='fixed top-3 right-3 cursor-pointer' onClick={() => setShowRCPDFReports(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#FBFBFB" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </span>
        <div className='flex items-center justify-center mt-[60px] mx-[20px]'>
          <RCPDFReports/>
        </div>
      </div>
    }
    </AnimatedPage>
  )
}

export default ServicesReports

const Dragables = ({ colums }) => (
  <div className="">
    <Draggable>
      {colums.map((item, key) => {
        return (
          <div key={key} className="">
            {item.name}
          </div>
        )
      })}
    </Draggable>
  </div>
)
