// eslint-disable-next-line no-undef
export const serviceDataSettings = {
  id: 60,
  request_no: "SR02-1130-0011",
  emirate: 1,
  emirate_data: {
    name: "AUH",
  },
  plate: "75295",
  auh_category: 10,
  auh_category_data: {
    name: "AUH",
    actual: "",
  },
  mileage: null,
  client: null,
  client_data: null,
  client_api_data: {
    OBJECT_NO: 1283701,
    PLATE_NO: "7529513",
    EMIRATES: "AD",
    CLIENT_NO: 11440,
    CLIENT_NAME: "LeasePlan Emirates Company Cars",
    MAKE: "Toyota",
    MODEL: "Previa,SE,",
    YEAR_MODEL: "2018",
    COLOR: "White",
    LAST_KNOWN_MILEAGE: 108092,
    REPLACEMENT_CATEGORY: "E",
    REPLACEMENT_CARS: "Toyota Yaris or similar",
    DRIVER_NAME: "Pool car Staff Trans",
    DRIVER_EMAIL: "zeenat.rahman@leaseplan.com",
    DRIVER_TELNO: "+971505710626",
  },
  driver_name: "POOL CAR STAFF TRANS",
  driver_contact: "+971505710626",
  driver_email: "zeenat.rahman@leaseplan.com",
  request_details: "zzzzzzzzzz",
  workshop: 5,
  workshop_data: {
    name: "MG TECH 3",
    address: "ASDA'A BCW - FALAK ST - DUBAI - UNITED ARAB EMIRATES",
    coordinates: { lat: 25.0962478, lng: 55.1588295 },
    added_date: "2022-12-08T08:30:10.975000+00:00",
    updated_date: "2022-12-08T08:30:53.875000+00:00",
  },
  collection_datetime: "2022-12-10T12:48:00+00:00",
  collection_location: "2 10A Street - Al Quoz - Al Quoz 1 - Dubai - United Arab Emirates",
  collection_coordinates: { lat: 25.175953, lng: 55.2517475 },
  delivery_date: "2022-12-30T12:49:38.652000+00:00",
  maintenance_po: "",
  maintenance_po_date: "",
  service_type_data: {
    name: "Accident Repair",
  },
  status_types_data: {
    name: "Todo",
  },
  status_sub_types_data: {
    name: "Service Scheduled",
  },
  replacement_car_data: {
    reg_no: "RC-11440",
    emirate_data: {
      name: "AUH",
    },
    plate: "75295",
    category_data: {
      id: 4,
      name: "E",
    },
    brand_data: {
      name: "Toyota",
    },
    make: null,
    model: "PREVIA,SE,",
    year: "2018",
    color: "WHITE",
    mileage: 108092,
    daily_rate: 2000,
    client_api_data: {
      OBJECT_NO: 1283701,
      PLATE_NO: "7529513",
      EMIRATES: "AD",
      CLIENT_NO: 11440,
      CLIENT_NAME: "LeasePlan Emirates Company Cars",
      MAKE: "Toyota",
      MODEL: "Previa,SE,",
      YEAR_MODEL: "2018",
      COLOR: "White",
      LAST_KNOWN_MILEAGE: 108092,
      REPLACEMENT_CATEGORY: "E",
      REPLACEMENT_CARS: "Toyota Yaris or similar",
      DRIVER_NAME: "Pool car Staff Trans",
      DRIVER_EMAIL: "zeenat.rahman@leaseplan.com",
      DRIVER_TELNO: "+971505710626",
    },
    pic: "385025a9-14f9-4ffb-a2a0-25529b80a9e4.gif",
    pics: [],
  },
  replacement_car_schedule: {
    start: "2022-12-28T00:00:00+00:00",
    end: "2022-12-29T00:00:00+00:00",
    added_date: "2022-12-30T12:49:38.798000+00:00",
    updated_date: "2022-12-30T12:59:12.306000+00:00",
  },
  lease_vehicle: {
    date_in: "2023-12-27",
    time_in: "20:00",
    km_in: "301897",
    fuel_in: "Full",
    description_in: "Service ",
    date_out: "2023-12-28",
    time_out: "17:35",
    km_out: "301907",
    fuel_out: "Full",
    description_out: "Service "
  },
  replacement_vehicle: {
    "date_out":"2023-12-27",
    "time_out":"20:00",
    "km_out":"8495",
    "fuel_out":"2 point ",
    "description_out":"",
    "date_in":"2023-12-28",
    "time_in":"17:35",
    "km_in":"8846",
    "fuel_in":"5 point ",
    "description_in":""
  },
  added_date: "2022-12-30T12:49:38.652000+00:00",
  updated_date: "2022-12-30T12:59:12.179000+00:00",
  added_by: 2,
  // updated_by: 2,
  added_by_data: {
    id: 17,
    email: 'admin@admin.com',
    name: 'Admin',
    name_first: 'admin',
    name_middle: '',
    name_last: 'admin',
  },
  updated_by_data: {
    id: 17,
    email: 'admin@admin.com',
    name: 'Admin',
    name_first: 'admin',
    name_middle: '',
    name_last: 'admin',
  }
}
